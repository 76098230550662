import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import VueApexCharts from 'vue3-apexcharts'
import { defineRule } from 'vee-validate'
import { required, email, min, between, confirmed } from '@vee-validate/rules'

import './assets/main.css'

import BootstrapVue3 from 'bootstrap-vue-3'
// import { createBootstrap } from 'bootstrap-vue-next'

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
// import './assets/login.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vue3-timepicker/dist/VueTimepicker.css'
const vuetify = createVuetify({
  components,
  directives
})

defineRule('required', required)
defineRule('email', email)
defineRule('min', min)
defineRule('between', between)
defineRule('confirmed', confirmed)

defineRule('required', value => {
  if (!value || !value.length) {
    return 'This field is required'
  }
  return true
})
defineRule('minLength', (value, [limit]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true
  }
  if (value.length < limit) {
    return `This field must be at least ${limit} characters`
  }
  return true
})
defineRule('minMax', (value, [min, max]) => {
  // The field is empty so it should pass
  if (!value || !value.length) {
    return true
  }
  const numericValue = Number(value)
  if (numericValue < min) {
    return `This field must be greater than ${min}`
  }
  if (numericValue > max) {
    return `This field must be less than ${max}`
  }
  return true
})
defineRule('confirmed', (value, [target]) => {
  if (value === target) {
    return true
  }
  return 'Passwords must match'
})

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(vuetify)
app.use(VueApexCharts)
app.use(BootstrapVue3)
// app.use(createBootstrap())

app.mount('#app')
